import useTranslation from 'next-translate/useTranslation'
import { useBetslipContext } from '../../../context/betslip.context'
import { isLoggedInCustomer, useUser } from '../../../lib/user'
import Switch from '../../forms/elements/Switch'

export const BetslipOddChangeHandlingSelector = () => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const { user } = useUser()

  return (
    <div className="betslip-account-selector flex justify-between items-center">
      {isLoggedInCustomer(user) && (
        <>
          <div
            className="betslip-account-selector-labels flex flex-col"
            onClick={betslipContext.toggleAcceptAllOddsChanges}
          >
            <span className="betslip-account-selector-label-title font-bold text-xs">
              {t('BetSlip.acceptAllOddsChangesTitle')}
            </span>
            <span className="betslip-account-selector-label-balance font-semibold text-xxs opacity-60">
              {t('BetSlip.acceptAllOddsChangesText')}
            </span>
          </div>
          <Switch
            enabled={betslipContext.acceptAllOddsChanges}
            onClick={betslipContext.toggleAcceptAllOddsChanges}
            handleColor={'bg-betslipContrast'}
          />
        </>
      )}
    </div>
  )
}
